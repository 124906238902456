.music {
    /* min-height: 100vh; */
    width: 500px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* padding-bottom:60px; */
}

.bandCamp {
width: 100%; 
height: 100%; 
padding: 1rem;
}

.albumCovers {
width: 55%; 
height: 100%; 
padding: 1rem;
}

#vignette002Row {
    padding-bottom: 9rem;
}

.noPadding {
    padding-left: 0;
    padding-right: 0;
 }

@media only screen and (max-width: 600px) {
    .bandCamp {
        width: 75%; 
        height: 150%; 
        }

        .albumCovers {
            width: 75%; 
            height: 100%; 
        }
    #vignette001 {
        padding-top: 50px;
        padding-bottom: 0;
        margin-bottom: 0; 
    }
    #vignette002 {
        padding-bottom: 0;
        margin-bottom: 0; 
    }
}

/* .otherWorks {
    width: 250px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8rem;
    margin-bottom: 5rem;;
    -webkit-user-drag: none;
}

.noDrag {
    -webkit-user-drag: none;
} */