.footer {
    background: rgba(255, 240, 197, 0) !important;
    position:relative;
    left:0;
    bottom:0;
    right:0;
}

.socials {
    color: white;
    display: inline-block;
    transition: transform .3s;
    width: 20px;
    height: auto;
    margin-right:0.8rem;
}

.socials:hover {
    transform: scale(1.2);
}
