.aboutMe {
    color: white;
    font-size: 3vw;
    margin-top:2rem;
    padding: 10px;
    width:100%;
    padding-bottom: 2px;
}

.smalltext {
    font-size: 2vw !important;
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .bigtext {
        font-size: 28px !important;
        text-align:left;
    }
    .smalltext {
        font-size: 16px !important;
        text-align: left;
    }
    .aboutMe {
        text-align: left;
    }
} 

#aboutpic {
    width: 950px;

}

#freddyk {
    color: #35ff69;
    text-decoration: underline;
}

#freddyk:hover {
    color: black;
    background-color: white;
}

/* 35ff69 */