.form {
    display: inline-block
}

.columns {
    margin: 2rem !important;
}

#nameForm, #emailForm, #textForm, #submitForm {
    font-family: 'Syne', sans-serif;
}

#submitForm {
    background-color: #018adb;
    margin-top: 3rem;
    /* color: black; */
}
#sayHello {
    width: 200px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

#contactMe {
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 50vh;
}

#email {
    color: #35ff69;
}


@media only screen and (max-width: 600px) {
    #contactForm {
        display:none;
    }
}

@media only screen and (min-width: 600px) {
    #contactMe { 
        display: none; 
    }  /* show it on small screens */
  }
