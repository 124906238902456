.myYellow {
    background-color: #003699!important;
}


#fbLogo,
#fbgif {
    width: 90px;
    height: auto;
    margin: 1rem;
}

.static {
    position: absolute;
    background: white;
    /* transition: 2s; */
}

.static:hover {
    opacity: 0;
}

.navOptions {
    display: inline-block;
    transition: transform .3s;
    font-family: obviously, sans-serif;
    font-weight: 800;
    font-size: 1 rem;
    font-style: normal;
    color: white !important;
    height: 100px;
    line-height: 100px;
}

.navOptions:hover {
    transform: scale(1.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("../imgs/navicon.png");
    background-size: 37px;
}



.navbar-togger,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    border:none;
    box-shadow: none;
}

.navbar-toggler {
    border:0px;
}
