
.a {
    vertical-align: middle !important;
    /* color: white !important; */
}

a {
    color: white;
}

a:hover {
    color: #35ff69;
}

.allWorks {
    font-size: 13px;
    vertical-align: middle;
    /* background-color: lightblue; */
}

.content, th {
    color: white !important;
}

#cvTable {
    margin-left: auto;
    margin-right: auto;
    background-color: #003699;
    width: 75%;
    /* border: none; */
}

.borderless td,
.borderless th {
    border: none;
}

.logo {
    width: 110px;
    height: auto;
    /* display: block; */
}

@media (max-width: 575.98px) {
    .allWorks {
        font-size: 10px;
        vertical-align: middle;
    }

    .logo {
        width: 100px;
        height: auto;
        padding-bottom: 1rem;
        /* display: block; */
    }
}